import { render, staticRenderFns } from "./home.vue?vue&type=template&id=3dbafadf&scoped=true"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"
import style0 from "./home.vue?vue&type=style&index=0&id=3dbafadf&prod&lang=scss&scoped=true"
import style1 from "./home.vue?vue&type=style&index=1&id=3dbafadf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbafadf",
  null
  
)

export default component.exports