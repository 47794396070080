<template>
  <div class="home">
    <div class="page-box">
      <div class="page-title">工作平台</div>
    </div>
    <el-row type="flex" :gutter="24">
      <el-col :span="14">
        <!-- 个人信息 -->
        <div class="common-functions">
          <div class="left">
            <img
              class="img-circle"
              v-if="$store.state.userInfo && $store.state.userInfo.UserPic"
              :src="$store.state.userInfo.UserPic"
            />
            <img v-else class="img-circle" src="@/assets/image/1.jpg" />

            <div class="left-box">
              <div class="name-box">
                <span v-if="$store.state.userInfo">{{
                  $store.state.userInfo.RealName
                    ? $store.state.userInfo.RealName
                    : $store.state.userInfo.UserName
                }}</span>
                <img class="edit" src="@/assets/image/ordinary/edit.png" />
              </div>
              <div class="id-box" v-if="$store.state.userInfo">
                ID:{{ $store.state.userInfo.UserName }}
              </div>
              <div class="PositionID" v-if="$store.state.userInfo.Employee">
                {{
                  $store.state.userInfo.Employee.PositionID
                    | positionFormater(positionList)
                }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right-box" @click="gourl2">
              <img class="right-icon" src="@/assets/image/ordinary/iocn1.png" />
              <div class="icon-box">
                <div class="name">消息通知</div>
                <div class="number">{{ emaliNumber }}</div>
              </div>
            </div>
            <div class="right-box" @click="gourl(0)">
              <img class="right-icon" src="@/assets/image/ordinary/iocn2.png" />
              <div class="icon-box">
                <div class="name">待审中</div>
                <div class="number">{{ dataCount1 }}</div>
              </div>
            </div>
            <div class="right-box" @click="gourl(4)">
              <img class="right-icon" src="@/assets/image/ordinary/iocn3.png" />
              <div class="icon-box">
                <div class="name">驳回</div>
                <div class="number">{{ dataCount2 }}</div>
              </div>
            </div>
            <div class="right-box" @click="gourl(1)">
              <img class="right-icon" src="@/assets/image/ordinary/iocn4.png" />
              <div class="icon-box">
                <div class="name">已通过</div>
                <div class="number">{{ dataCount3 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 审批事项 -->
        <div class="examine">
          <div class="title-box">
            <div class="title">常用功能</div>
          </div>
          <div class="card-box">
            <div
              class="card-item"
              v-for="item in commonFunctions"
              :key="item.name"
              @click="goTo(item)"
            >
              <img :src="item.icon" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="title-box">
            <div class="title">其它应用</div>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="`人员信息`" name="first">
              <div class="list">
                <div class="card-box">
                  <div
                    class="card-item"
                    v-for="item in commonFunctions1"
                    :key="item.name"
                    @click="goToBox(item)"
                  >
                    <img :src="item.icon" />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="`外事业务`" name="second">
              <div class="list">
                <div class="card-box">
                  <div
                    class="card-item"
                    v-for="item in commonFunctions2"
                    :key="item.name"
                    @click="goToBox(item)"
                  >
                    <img :src="item.icon" />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div></el-tab-pane
            >
            <el-tab-pane :label="`人事业务`" name="third">
              <div class="list">
                <div class="card-box">
                  <div
                    class="card-item"
                    v-for="item in commonFunctions3"
                    :key="item.name"
                  >
                    <img :src="item.icon" />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div></el-tab-pane
            >
            <el-tab-pane :label="`我的证件`" name="fourth">
              <div class="list">
                <div class="card-box">
                  <div
                    class="card-item"
                    v-for="item in commonFunctions4"
                    :key="item.name"
                    @click="goToBox(item)"
                  >
                    <img :src="item.icon" />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div></el-tab-pane
            >
            <el-tab-pane :label="`其它应用`" name="fifth">
              <div class="list">
                <div class="card-box">
                  <div
                    class="card-item"
                    v-for="item in commonFunctions5"
                    :key="item.name"
                    @click="goToBox(item)"
                  >
                    <img :src="item.icon" />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div></el-tab-pane
            >
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="10" class="col_right">
        <!-- 登录提示 -->
        <div class="login-prompt">
          <img
            class="weather"
            :src="require('@/assets/image/home/weather.png')"
          />
          <div class="tips-box">
            <div>
              {{
                $store.state.userInfo.RealName
                  ? $store.state.userInfo.RealName
                  : $store.state.userInfo.UserName
              }}你好！开启元气满满的一天～
            </div>
            <div class="tips">
              <span>登录于： {{ $store.state.userInfo.LastLoginDate }}</span>
              <!-- <span class="pm">PM13:00</span> -->
            </div>
          </div>
        </div>

        <!-- 通知公告 -->
        <div class="notice">
          <div class="title-box">
            <div class="title">通知公告</div>
            <!-- <div class="manage" @click="goMoreNotice">更多</div> -->
          </div>
          <div class="notice-card">
            <div
              class="notice-item"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="showNoticeItem(item)"
            >
              <div class="left">
                <span class="circle"></span>
                <div class="name">{{ item.Title }}</div>
              </div>
              <div class="right">{{ item.SendTime }}</div>
            </div>
          </div>
        </div>
        <!-- 待我查阅 -->
        <div class="refer" ref="refer">
          <div class="title-box">
            <div class="title">日程安排</div>
            <div class="manage">添加</div>
          </div>
          <div class="refer-box" v-if="readList.length !== 0"></div>
          <div class="tips-box" v-else>暂无数据</div>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="24">
      <el-col :span="24">
        <div class="footer">
          Copyright © 2024 HRMS/SPEC/SNEI All Rights Reserved.
        </div>
      </el-col>
    </el-row>

    <!-- 添加常用功能 -->
    <el-drawer
      title="常用功能"
      :visible.sync="commonFunctionsDrawer"
      :direction="direction"
      :before-close="handleFunctionClose"
      :with-header="false"
    >
      <div class="add-common">
        <div class="herder-box">
          <div class="left">
            <img
              class="arrow"
              :src="require('@/assets/image/home/arrow.png')"
            />
            <div>常用功能</div>
          </div>
          <img
            class="close"
            @click="handleFunctionClose"
            :src="require('@/assets/image/home/close.png')"
          />
        </div>
        <div class="line"></div>

        <div class="search-box">
          <input placeholder="搜索" />
          <img
            class="search"
            :src="require('@/assets/image/home/search2.png')"
          />
        </div>

        <div class="recent-searches">
          <div class="title">最近搜索</div>
          <div class="lable-box">
            <div class="leble" v-for="(item, index) in lables" :key="index">
              {{ item }}
            </div>
          </div>
        </div>

        <div class="card-box">
          <div
            class="card-item"
            v-for="(item, index) in allCommonFunctions"
            :key="index"
          >
            <div class="left">
              <img :src="item.icon" />
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="right">
              <img
                v-if="!item.isActive"
                @click="addFunctions(index)"
                class="add"
                :src="require('@/assets/image/home/add.png')"
              />
              <div v-else class="delete-box">
                <div class="is-add">已添加</div>
                <img
                  class="delete"
                  @click="deleteFunctions(index)"
                  :src="require('@/assets/image/home/delete.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 公告详情 -->
    <el-drawer
      title="公告详情"
      :visible.sync="noticeDrawer"
      :direction="direction"
      :before-close="handleNoticeClose"
      :with-header="false"
    >
      <div class="add-common">
        <div class="herder-box">
          <div class="left">
            <img
              class="arrow"
              :src="require('@/assets/image/home/arrow.png')"
            />
            <div>公告详情</div>
          </div>
          <img
            class="close"
            @click="handleNoticeClose"
            :src="require('@/assets/image/home/close.png')"
          />
        </div>
        <div class="line"></div>
        <div class="content">
          <div class="title">标题: {{ Title }}</div>
          <div class="time-box">
            <span>发布于{{ SendTime }}</span>
          </div>
          <div class="html" v-html="html"></div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getNews,
  getNewsId,
  allPosition,
  getMessages,
  getApplication,
} from "@/api/user.js";
export default {
  filters: {
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
  },
  data() {
    return {
      commonFunctions: [
        {
          name: "请假",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-qingjia.png"),
        },
        {
          name: "加班",
          icon: require("@/assets/image/ordinary/icon-jiaban.png"),
          nameEn: "Overtime",
        },
        {
          name: "撤离",
          icon: require("@/assets/image/ordinary/icon-cheli.png"),
          nameEn: "Evacuate",
        },
        {
          name: "机票请购",
          icon: require("@/assets/image/ordinary/icon-jpqg.png"),
          nameEn: "Ticket requisition",
        },
        {
          name: "机票变更",
          icon: require("@/assets/image/ordinary/icon-jpbg.png"),
          nameEn: "Ticket change",
        },
        {
          name: "工资条",
          icon: require("@/assets/image/ordinary/icon-gzt.png"),
          nameEn: "Payslip",
        },
        {
          name: "个税清缴",
          icon: require("@/assets/image/ordinary/icon-gsqj.png"),
          nameEn: "taxClearAll",
        },
        {
          name: "岗位变更",
          icon: require("@/assets/image/ordinary/icon-gwbg.png"),
          nameEn: "taxClearAll",
        },
      ],
      commonFunctions1: [
        {
          name: "基本信息",
          icon: require("@/assets/image/ordinary/icon-jbxx.png"),
          nameEn: "Basic Info",
          path: "/ordinary/boxOneBasicInfo",
        },
        {
          name: "劳动关系",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-ldgx.png"),
          path: "/ordinary/boxOneLabor",
        },
        {
          name: "专业技术",
          icon: require("@/assets/image/ordinary/icon-zyjs.png"),
          nameEn: "Overtime",
          path: "/ordinary/boxOneSeciality",
        },
        {
          name: "银行账户",
          nameEn: "Bank",
          icon: require("@/assets/image/ordinary/icon-yhxx.png"),
          path: "/ordinary/boxOneBank",
        },
        {
          name: "人员薪资",
          nameEn: "Bank",
          icon: require("@/assets/image/ordinary/icon-ryxz.png"),
          path: "/ordinary/boxOneSalary",
        },
      ],
      commonFunctions2: [
        {
          name: "外事信息",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-wsxx.png"),
          path: "/ordinary/boxTwoFreign",
        },
      ],
      commonFunctions3: [
        {
          name: "年度考勤",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-ndkq.png"),
        },
      ],
      commonFunctions4: [
        {
          name: "护照",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-hz.png"),
        },
        {
          name: "签证",
          icon: require("@/assets/image/ordinary/icon-qz.png"),
          nameEn: "Overtime",
        },
        {
          name: "暂住证",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-zzz.png"),
        },
        {
          name: "收入证明",
          icon: require("@/assets/image/ordinary/icon-srzm.png"),
          nameEn: "Overtime",
          path: "/ordinary/incomeCertificate",
        },
        {
          name: "工作证明",
          icon: require("@/assets/image/ordinary/icon-gzzm.png"),
          nameEn: "Overtime",
          path: "/ordinary/proofEmployment",
        },
        {
          name: "离职证明",
          icon: require("@/assets/image/ordinary/icon-lzzm.png"),
          nameEn: "lzzm",
          path: "/ordinary/employmentSeparation",
        },
      ],
      commonFunctions5: [
        {
          name: "优化意见",
          nameEn: "Leave",
          icon: require("@/assets/image/ordinary/icon-yhyj.png"),
          path: "/ordinary/feedbacks",
        },
      ],
      allCommonFunctions: [
        {
          name: "人员审批",
          icon: require("@/assets/image/home/icon-rysp.png"),
          isActive: true,
        },
        {
          name: "签证办理",
          icon: require("@/assets/image/home/icon-qzbl.png"),
          isActive: true,
        },
        {
          name: "任务批件",
          icon: require("@/assets/image/home/icon-rwpj.png"),
          isActive: true,
        },
        {
          name: "人员动迁",
          icon: require("@/assets/image/home/icon-rydq.png"),
          isActive: true,
        },
        {
          name: "机票管理",
          icon: require("@/assets/image/home/icon-jpgl.png"),
          isActive: false,
        },
      ],
      serachCommonFunctions: [],
      activeName: "first",
      activeName2: "first",
      examineList: [
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: true,
          date: "2024-01-04",
          timer: "16:00",
          tips: "已滞留24小时",
          title: "请签字xxx提交的申请请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 2,
          isFire: true,
          date: "2024-01-04",
          timer: "16:00",
          tips: "已滞留24小时",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 2,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
      ],
      noticeList: [],
      flightList: [
        {
          passportNumber: "123456",
          day: "10",
          date: "2024.01",
          start_time: "10:00",
          start_airport: "虹桥T2",
          aviation: "海南航空HN1234",
          duration: "10个小时",
          end_time: "10:00",
          end_airport: "沙特T2",
        },
        {
          passportNumber: "123456",
          day: "10",
          date: "2024.01",
          start_time: "10:00",
          start_airport: "虹桥T2",
          aviation: "海南航空HN1234",
          duration: "10个小时",
          end_time: "10:00",
          end_airport: "沙特T2",
        },
      ],
      templateList: [
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template1.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template2.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template1.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template2.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template1.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template2.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template1.png"),
        },
        {
          name: "表格模板",
          icon: require("@/assets/image/home/template2.png"),
        },
      ],
      lables: ["任务审批", "任务审批任务审批"],
      value1: null,
      value2: null,
      commonFunctionsDrawer: false,
      direction: "rtl",
      noticeDrawer: false,
      html: "",
      Title: "",
      SendTime: "",
      todoList: [],
      processedList: [],
      trackingList: [],
      endList: [],
      url: "https://hrms.baishulai.com/",
      readList: [],
      positionList: [],
      dataCount1: 0,
      dataCount2: 0,
      dataCount3: 0,
      emaliNumber: 0,
    };
  },
  methods: {
    handleClick() {},
    //添加常用功能抽屉关闭
    handleFunctionClose() {
      this.commonFunctionsDrawer = false;
    },
    // 公告详情关闭
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    //通知公告
    getNews() {
      const data = {
        page: 1,
        size: 10,
      };
      getNews(data).then((res) => {
        if (res.status === 200) {
          this.noticeList = res.response.data;
        }
      });
    },
    //获取通知公告详情
    showNoticeItem(data) {
      getNewsId({ id: data.ID }).then((res) => {
        if (res.status === 200) {
          this.noticeDrawer = true;
          this.Title = res.response.Title;
          this.html = res.response.MsgContent;
          this.SendTime = data.SendTime;
        }
      });
    },
    goMoreNotice() {
      location.href = this.url + "/pages/NEWS/News.aspx";
    },
    //跳转
    goTo(data) {
      switch (data.name) {
        case "请假":
          this.$router.push(`/ordinary/leave`);
          break;
        case "加班":
          this.$router.push(`/ordinary/overtime`);
          break;
        case "撤离":
          this.$router.push(`/ordinary/evacuate`);
          break;
        case "机票请购":
          this.$router.push(`/ordinary/ticketRequisition`);
          break;
        case "机票变更":
          this.$router.push(`/ordinary/ticketChange`);
          break;
        case "工资条":
          this.$router.push(`/ordinary/payslip`);
          break;
        case "个税清缴":
          this.$router.push(`/ordinary/taxClearAll`);
          break;
        case "岗位变更":
          this.$router.push(`/ordinary/positionAdjustmentApplicantion`);
          break;
      }
    },
    gourl(index) {
      this.$router.push(`/ordinary/examine?type=${index}`);
    },
    gourl2() {
      this.$router.push(`/ordinary/systemMessage`);
    },
    goToBox(item) {
      this.$router.push(item.path);
    },
  },
  created() {
    this.getNews();
    if (!this.$store.state.userInfo) {
      this.$store.commit(
        "changeUserInfo",
        JSON.parse(localStorage.getItem("userInfo"))
      );
    }
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    getMessages({ messageType: 4 }).then((res) => {
      console.log("getMessages", res);
      if (res.status === 200) {
        this.emaliNumber = res.response.dataCount;
      }
    });
    getApplication().then((res) => {
      if (res.status === 200) {
        const list = res.response;
        if (list && list.length !== 0) {
          list.forEach((item) => {
            if (item.AuditStatus === 0) {
              this.dataCount1 = item.Amount;
            }
            if (item.AuditStatus === 4) {
              this.dataCount2 = item.Amount;
            }
            if (item.AuditStatus === 1) {
              this.dataCount3 = item.Amount;
            }
          });
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100%;
  background-color: #ecf0f5;
  box-sizing: border-box;
  padding: 24px;

  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .el-row {
    // 常用功能
    .common-functions {
      width: 100%;
      min-height: 187px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .img-circle {
          width: 108px;
          height: 108px;
          border-radius: 54px;
        }
        .left-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 20px;
          .name-box {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #232323;
            .edit {
              width: 16px;
              height: 16px;
              margin-left: 6px;
            }
          }
          .id-box {
            width: auto;
            height: 22px;
            line-height: 22px;
            color: #fff;
            border-radius: 11px;
            padding: 0 12px;
            background-color: #316ff5;
            max-width: 90px;
            text-align: center;
            font-size: 14px;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          .PositionID {
            font-size: 14px;
          }
        }
      }
      .right {
        display: flex;
        width: 100%;
        margin-left: 20px;
        .right-box {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .right-icon {
            width: 54px;
            height: 54px;
            margin-right: 15px;
          }
          .icon-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .name {
              font-size: 14px;
              color: #666666;
              margin-bottom: 10px;
            }
            .number {
              font-size: 20px;
              color: #333333;
            }
          }
        }
      }
    }
    // 登录提示
    .login-prompt {
      width: 100%;
      height: 136px;
      background-image: url("../../assets/image/home/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 24px;
      display: flex;
      align-items: center;
      .weather {
        width: 62px;
        height: 62px;
        margin-left: 23px;
        margin-right: 26px;
      }
      .tips-box {
        font-size: 14px;
        color: #fff;
        .tips {
          margin-top: 17px;
        }
        .pm {
          margin-left: 24px;
        }
      }
    }
    //待我审批
    .examine {
      width: 100%;
      min-height: 303px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .card-box {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: #333333;
        margin-bottom: 24px;
        .card-item {
          width: 64px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 50px;
          margin-top: 20px;
          cursor: pointer;
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 12px;
          }
        }
        .card-last {
          margin-right: 0;
        }
      }
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .el-tabs {
        margin-top: 12px;
      }
      .list {
        height: 200px;
        overflow: auto;
        position: relative;
        .list-box {
          display: flex;
          flex-direction: column;
          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 18px;
            cursor: pointer;
            .left {
              display: flex;
              align-items: center;
              .processed {
                width: 6px;
                height: 6px;
                background: #ff8c38;
                opacity: 1;
                border-radius: 3px;
                margin-right: 11px;
              }

              .title {
                width: 200px;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; // 默认不换行
                .emergent {
                  display: inline-block;
                  width: 34px;
                  height: 16px;
                  line-height: 16px;
                  background: #ecf6ff;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                  color: #316ff5;
                  font-size: 10px;
                  text-align: center;
                  margin-right: 16px;
                  position: relative;
                  top: -3px;
                }
                .emergent2 {
                  display: inline-block;
                  width: 34px;
                  height: 16px;
                  line-height: 16px;
                  background: #e5faf2;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                  color: #009d67;
                  font-size: 10px;
                  text-align: center;
                  margin-right: 16px;
                  position: relative;
                  top: -3px;
                }
                color: #333333;
                font-size: 14px;
                margin-right: 10px;
              }
              .is-fire {
                width: 14px;
                height: 14px;
                margin-right: 50px;
                .fire {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .date-timer {
              display: flex;
              justify-content: flex-start;
              flex-shrink: 0;
              width: 150px;
              text-align: left;
              margin-right: 50px;
              .date {
                color: #999999;
                margin-right: 12px;
                font-size: 14px;
              }
              .timer {
                color: #999999;
                font-size: 14px;
              }
            }
            .right {
              text-align: right;
              width: 120px;
              color: #333333;
              font-size: 14px;
            }
          }
        }
        .tips-box {
          font-size: 14px;
          position: absolute;
          left: 50%;
          top: 20%;
          transform: translateX(-50%);
          color: #5e6d82;
        }
      }
    }
    //通知公告
    .notice {
      width: 100%;
      min-height: 200px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .notice-card {
        height: 148.8px;
        overflow: auto;
        .notice-item {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          cursor: pointer;
          .left {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .circle {
              display: inline-block;
              width: 5px;
              height: 5px;
              background: #dedede;
              opacity: 1;
              border-radius: 2.5px;
              margin-right: 10px;
            }
            .name {
              display: inline-block;
              max-width: 170px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; // 默认不换行
              font-size: 14px;
            }
          }
          .right {
            color: #999999;
            font-size: 14px;
            flex-shrink: 0;
          }
        }
      }
    }
    //机票行程
    .flight-itinerary {
      width: 100%;
      min-height: 328px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .textbox {
          display: flex;
          align-items: center;
          .el-date-editor {
            width: 150px;
          }
          .input-box {
            position: relative;
            margin-left: 25px;
            input {
              width: 150px;
              height: 40px;
              box-sizing: border-box;
              padding-left: 11px;
              padding-right: 40px;
              border: none;
              outline: none;
              border: 1px solid #dcdfe6;
              border-radius: 4px;
            }
            .search {
              position: absolute;
              width: 18px;
              height: 18px;
              right: 10px;
              top: 11px;
            }
          }
        }
      }
      .list {
        height: 210px;
        overflow: auto;
        .list-box {
          display: flex;
          flex-direction: column;
          .list-item {
            width: 100%;
            height: 86px;
            background: #fafafa;
            border-radius: 4px 4px 4px 4px;
            margin-top: 19px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              display: flex;
              .title-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-left: 19px;
                font-size: 14px;
                color: #333333;
                .number {
                  margin-top: 10px;
                }
              }
              .time-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-left: 19px;
                font-size: 14px;
                color: #333333;
                margin-left: 45px;
                color: #337ab7;
                .day {
                  font-size: 19px;
                  font-weight: bolder;
                }
                .date {
                  font-size: 14px;
                }
              }
            }
            .right {
              width: 100%;
              margin-left: 35px;
              height: 78px;
              background: #ffffff;
              border-radius: 4px;
              margin-right: 4px;
              display: flex;
              justify-content: space-around;
              align-items: flex-end;
              .location-left {
                margin-bottom: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .start_time {
                  font-weight: bolder;
                  margin-bottom: 9px;
                }
              }
              .location-center {
                margin-bottom: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .aircraft {
                  width: 42px;
                  height: 14px;
                  margin-bottom: 8px;
                }
                .aviation {
                  font-size: 12px;
                  margin-bottom: 5px;
                }
              }
              .location-right {
                margin-bottom: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .end_time {
                  font-weight: bolder;
                  margin-bottom: 9px;
                }
              }
            }
          }
        }
      }
    }
    //待我查阅
    .refer {
      width: 100%;
      height: calc(100% - 413px);
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      position: relative;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .refer-box {
        min-height: 370px;
        max-height: 370px;
        overflow: auto;
        margin-top: 24px;
        position: relative;
        .refer-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin-bottom: 20px;
          .refer-left {
            width: 300px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行
            color: #333333;
            .refer-circle {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 2.5px;
              background-color: #dedede;
              margin-right: 5px;
            }
          }
          .refer-right {
            color: #999999;
          }
        }
      }
      .tips-box {
        font-size: 14px;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translateX(-50%);
        color: #5e6d82;
      }
    }
    //常用模板
    .template {
      width: 100%;
      min-height: 181px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
      }
      .card-box {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: #333333;
        .card-item {
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 70px;
          margin-top: 36px;
          cursor: pointer;
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 12px;
          }
        }
        .card-last {
          margin-right: 0;
        }
      }
    }
    //页脚
    .footer {
      text-align: center;
      margin-top: 24px;
      font-size: 14px;
      color: #06052b;
    }
  }
  .add-common {
    width: 100%;
    height: 100%;
    padding: 20px 25px;
    box-sizing: border-box;
    .html {
      word-wrap: break-word; /* 旧版浏览器支持 */
      overflow-wrap: break-word; /* 标准属性 */
    }
    .herder-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
        font-weight: bolder;
        .arrow {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .close {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    .line {
      width: 100%;
      border: 1px solid #ededed;
      margin-top: 25px;
    }
    .search-box {
      position: relative;
      margin-top: 21px;
      input {
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border: none;
        padding-left: 44px;
        padding-right: 11px;
        outline: none;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }
      .search {
        position: absolute;
        top: 10px;
        left: 13px;
        width: 16px;
        height: 16px;
      }
    }
    .recent-searches {
      .title {
        margin-top: 37px;
        font-size: 14px;
        color: #333333;
        margin-bottom: 31px;
      }
      .lable-box {
        display: flex;
        flex-wrap: wrap;
        .leble {
          height: 30px;
          line-height: 30px;
          background: #efefef;
          border-radius: 2px;
          padding: 0 10px;
          margin-right: 24px;
          font-size: 14px;
          color: #333333;
        }
      }
    }
    .card-box {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      .card-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        margin-bottom: 15px;
        .left {
          display: flex;
          font-size: 14px;
          color: #333333;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            margin-right: 26px;
          }
        }
        .right {
          .add {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
          .delete-box {
            display: flex;
            align-items: center;
            .is-add {
              width: 58px;
              height: 30px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #cccccc;
              color: #cccccc;
              font-size: 14px;
              line-height: 30px;
              text-align: center;
              margin-right: 26px;
            }
            .delete {
              width: 16px;
              height: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .content {
      .title {
        font-size: 14px;
        color: #333333;
        margin-top: 27px;
      }
      .time-box {
        font-size: 12px;
        color: #999999;
        margin-top: 18px;
        margin-bottom: 18px;
        span {
          margin-right: 30px;
        }
      }
      .html {
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
</style>
<style scoped>
.examine >>> .is-active {
  color: #337ab7;
}
.examine >>> .el-tabs__active-bar {
  height: 2px;
  background-color: #337ab7;
}
.examine >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #efefef;
}
.examine >>> .el-tabs__item {
  padding: 0 30px;
}
.examine >>> .el-tabs__header {
  margin-bottom: 0;
}
.flight-itinerary >>> .el-tabs__header {
  margin-bottom: 0;
}
.flight-itinerary >>> .is-active {
  color: #337ab7;
}
.flight-itinerary >>> .el-tabs__active-bar {
  height: 2px;
  background-color: #337ab7;
}
.flight-itinerary >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #efefef;
}
.flight-itinerary >>> .el-tabs__item {
  padding: 0 30px;
}
input::-webkit-input-placeholder {
  color: #dddddd;
}
/* Firefox */
input::-moz-placeholder {
  color: #dddddd;
  opacity: 1;
}
/* IE */
input:-ms-input-placeholder {
  color: #dddddd;
}
</style>